<template>
    <div>
        <v-card flat :loading="loading">
            <v-row class="ml-2 align-center" no-gutters>
                <v-col cols="2" class="align-center">
                    <span
                        >Business <br />
                        Purchasing Exchange Rates</span
                    >
                </v-col>
                <v-col cols="2" class="ml-4 align-center">
                    <v-autocomplete
                        v-if="setting"
                        v-model="currency"
                        placeholder="Currency"
                        solo
                        flat
                        hide-details
                        :items="itemsCurrency"
                        @change="saveCurrency"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-divider class="mt-3" />
            <v-row>
                <v-toolbar flat>
                    <v-toolbar-title>Exchanges</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="newItem"
                        rounded
                        class="primary"
                        elevation="0"
                    >
                        Add New
                    </v-btn>
                    <ExchangeForm
                        title="New Exchange"
                        v-model="dialogExchangeNew"
                        :createForm="true"
                        :item="newValue"
                        :itemsCurrency="itemsCurrency"
                        @closeDialog="dialogNew"
                        @saveExchange="saveExchange"
                    />
                </v-toolbar>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="exchanges"
                class="elevation-0 pa-1"
                :mobile-breakpoint="0"
                hide-default-footer
                fixed-header
            >
                <template v-slot:top> </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon small @click="editItem(item)">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <ExchangeForm
                        v-if="dialogExchangeEdit"
                        title="Edit Exchange"
                        v-model="dialogExchangeEdit"
                        :createForm="false"
                        :item="itemToEdit"
                        :itemsCurrency="itemsCurrency"
                        @closeDialog="dialogEdit"
                        @saveExchange="saveExchange"
                    />

                    <v-btn icon small @click="deleteItem(item)">
                        <v-icon small>mdi-trash-can</v-icon>
                    </v-btn>

                    <v-dialog
                        :retain-focus="false"
                        v-model="deleteDialog"
                        persistent
                        max-width="400px"
                    >
                        <v-card>
                            <v-card-title class="text-h5">Delete</v-card-title>
                            <v-card-text>
                                Are you sure you want to delete this exchange?
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    text
                                    color="secondary"
                                    @click="deleteDialog = false"
                                >
                                    Close
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="deleteExchange"
                                    text
                                    color="error"
                                >
                                    Delete
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
                <template v-slot:[`item.money`]="{ item }">
                    ${{ item.money }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import codesCurrency from '@/assets/codes-all_json.json'
import _ from 'lodash'
import ExchangeForm from '@/components/Settings/ExchangeForm'
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'SettingsCurrencies',
    components: {
        ExchangeForm,
    },
    props: {
        setting: Object,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Currency',
                    value: 'currency',
                },
                {
                    text: 'Price',
                    value: 'money',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                },
            ],
            currency: null,
            money: null,
            loading: false,
            loadingError: false,
            error: null,
            errorMsg: null,
            exchanges: [],
            valid: false,
            deleteDialog: false,
            itemToDelete: null,
            newValue: { currency: 0, money: 0 },
            itemToEdit: { currency: 0, money: 0 },
            dialogExchangeEdit: false,
            dialogExchangeNew: false,
        }
    },
    watch: {
        setting: function() {
            this.setExchanges()
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        saveCurrency: async function(event) {
            this.loadingError = true
            this.setting.globalCurrency = event
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateSetting(id, this.setting)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        saveExchange: async function(item) {
            this.loading = true
            if (this.dialogExchangeEdit) {
                const index = this.setting.exchanges.findIndex(
                    i => i.currency === item.currency
                )
                this.setting.exchanges.splice(index, 1, item)
            } else {
                this.setting.exchanges.push(item)
            }
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateSetting(id, this.setting)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.dialogExchangeEdit = false
                this.dialogExchangeNew = false
                this.newValue = { currency: 0, money: 0 }
            }
        },
        deleteItem(item) {
            this.deleteDialog = true
            this.itemToDelete = _.cloneDeep(item)
        },
        editItem(item) {
            this.itemToEdit = _.cloneDeep(item)
            this.dialogExchangeEdit = true
        },
        dialogEdit() {
            this.dialogExchangeEdit = false
        },
        dialogNew() {
            this.dialogExchangeNew = false
            this.newValue = { currency: 0, money: 0 }
        },
        newItem() {
            this.newValue = { currency: 0, money: 0 }
            this.dialogExchangeNew = true
        },
        deleteExchange: async function() {
            const index = this.exchanges.findIndex(
                x => x.currency == this.itemToDelete.currency
            )
            this.exchanges.splice(index, 1)
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateSetting(id, this.setting)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        setExchanges() {
            if (!this.setting.exchanges) {
                this.setting.exchanges = []
            } else {
                this.exchanges = this.setting.exchanges
                this.currency = this.setting.globalCurrency
            }
        },
    },
    created() {
        const itemsCurrency = codesCurrency.map(x => x.AlphabeticCode)
        this.itemsCurrency = itemsCurrency.filter(x => x != null)
        if (this.setting) {
            this.setExchanges()
        }
    },
}
</script>

<style>
.v-data-table-header th {
    background-color: #eeeeee !important
}
.v-data-table__wrapper {
    max-height: 60vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px; 
}
.v-data-table__wrapper thead tr {
    position: sticky; 
    top: 0;
    z-index: 10;
}
thead {
    position: sticky;
    z-index: 1;
}
</style>